import { Auth } from "aws-amplify";
import superagent from "superagent";

const api_url =
  "https://yc4oxhkfjd.execute-api.eu-central-1.amazonaws.com/Prod";

class ApiService {
  async sleep(duration = 1000) {
    return await new Promise((resolve) =>
      setTimeout(() => resolve(), duration)
    );
  }

  async sendImage(imageSrc, requestId = null) {
    const base64 = imageSrc.replace(/^data:image\/\w+;base64,/, "");
    const buf = Uint8Array.from(atob(base64), (c) => c.charCodeAt(0));

    try {
      const result = await superagent
        .post(`${api_url}/rekognize`)
        .set("accept", "application/json")
        .set("content-type", "application/json")
        .set("authorization", `Bearer ${await this.getToken()}`)
        .ok(() => true)
        .send({ image: base64, requestId });

      return {
        Data: result.body,
        Code: result.statusCode,
      };
    } catch (err) {
      console.error(err);
    }
  }

  async getToken() {
    const data = await Auth.currentSession();

    return data.getIdToken().jwtToken;
  }

  async getObjects() {
    const result = await superagent
      .get(`${api_url}/objects`)
      .set("accept", "application/json")
      .set("authorization", `Bearer ${await this.getToken()}`)
      .send();

    return result.body;
  }

  async getCompanies() {
    const result = await superagent
      .get(`${api_url}/companies`)
      .set("accept", "application/json")
      .set("authorization", `Bearer ${await this.getToken()}`)
      .send();

    return result.body;
  }
}

export default ApiService;
