import React, { useEffect, useState } from "react";
import ApiService from "../../services/ApiService";
import WordCloud from "../word-cloud/WordCloud";

const apiService = new ApiService();

function CompanyCloud() {
  const [data, setData] = useState([]);
  const [changedTags, setChangedTags] = useState([]);

  useEffect(() => {
    const interval = setInterval(async () => {
      const items = await apiService.getCompanies();

      setData((data) => {
        const newItems = items.map(({ amount, company }) => ({
          value: company,
          count: parseInt(amount),
        }));

        const newTags = newItems
          .filter(
            (item) =>
              !data.find(
                (oldItem) =>
                  item.value === oldItem.value && item.count === oldItem.count
              )
          )
          .map(({ value }) => value);
        setChangedTags(newTags);

        return newItems;
      });
    }, 5000);

    return () => clearInterval(interval);
  }, [setData]);

  return <WordCloud data={data} changedTags={changedTags}></WordCloud>;
}

export default CompanyCloud;
