import GlitchedWriter from "glitched-writer";
import React from "react";
import { TagCloud } from "react-tagcloud";

const options = {
  luminosity: "dark",
  hue: "#FF9900",
};

function WordCloud({ data, changedTags }) {
  const customRenderer = (tag, size, color) => {
    return (
      <div
        ref={(divRef) => {
          if (changedTags.indexOf(tag.value) >= 0) {
            const writer = new GlitchedWriter(
              divRef, // Element / Selector string / undefined
              {
                interval: [50, 90],
                delay: [0, 500],
                steps: [5, 8],
                maxGhosts: 0,
                ghostChance: 0,
                changeChance: 1,
                glyphs: "1234567890QWERTYUIOPASDFGHJKLZXCVBNM#$%",
                fillSpace: false,
                mode: "normal",
                oneAtATime: 0,
              }
            );
            writer.queueWrite(["", tag.value], 100, false);
            // .then(() => writer.write(tag.value));
          }
        }}
        key={tag.value}
        style={{
          fontSize: `${size / 2}em`,
          margin: "3px",
          padding: "3px",
          display: "inline-block",
          color: color,
        }}
      >
        {changedTags.indexOf(tag.value) >= 0 ? "" : tag.value}
      </div>
    );
  };

  return (
    <TagCloud
      tags={data}
      minSize={1}
      maxSize={5}
      renderer={customRenderer}
      colorOptions={options}
    />
  );
}

export default WordCloud;
