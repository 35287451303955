import React, { useImperativeHandle, useState } from "react";
import Webcam from "react-webcam";

const videoConstraints = {
  facingMode: "user",
};

const FreezableWebcam = React.forwardRef(({ src, frozen }, ref) => {
  const [isFrozen, setIsFrozen] = useState(frozen);
  const [currentSrc, setCurrentSrc] = useState(src);
  const webcamRef = React.useRef(null);

  useImperativeHandle(ref, () => ({
    capture: () => {
      if (isFrozen) {
        return currentSrc;
      }
      const newSrc = webcamRef.current.getScreenshot();

      setIsFrozen(true);
      setCurrentSrc(newSrc);

      return newSrc;
    },

    unfreeze: () => setIsFrozen(false),
  }));

  return (
    <>
      {!isFrozen ? (
        <div>
          <Webcam
            audio={false}
            screenshotFormat="image/jpeg"
            width="100%"
            videoConstraints={videoConstraints}
            ref={webcamRef}
          />
        </div>
      ) : (
        <img src={currentSrc} />
      )}
    </>
  );
});

export default FreezableWebcam;
