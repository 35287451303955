import React, { useCallback, useEffect, useRef, useState } from "react";
import FreezableWebcam from "../webcam/FreezableWebcam";

import {
  Badge,
  Button,
  ColumnLayout,
  Container,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import useWebSocket from "react-use-websocket";
import ApiService from "../../services/ApiService";

const apiService = new ApiService();

function Rekognizer() {
  const [loading, setLoading] = useState(false);
  const [badges, setBadges] = useState([]);

  const cameraRef = useRef();
  const canvasRef = useRef(null);

  const onSubmitted = React.useCallback(
    async (imageSrc, width, height, requestId) => {
      cameraRef.current.unfreeze();
      const result = await apiService.sendImage(imageSrc, requestId);
      setLoading(false);

      if (!result) {
        return;
      }

      if (result.Code < 400) {
        const context = canvasRef.current.getContext("2d");
        const newBadges = [];

        result.Data.forEach((item) => {
          newBadges.push(item.Name);
          item.Instances.forEach((instance) => {
            console.log(instance);
            context.strokeStyle = "black";
            context.strokeRect(
              instance.BoundingBox.Left * width,
              instance.BoundingBox.Top * height,
              instance.BoundingBox.Width * width,
              instance.BoundingBox.Height * height
            );
          });
        });
        setBadges(newBadges);
      }
    },
    [canvasRef]
  );

  const capture = useCallback(
    async (requestId = null) => {
      setLoading(true);
      setBadges([]);

      const imageSrc = cameraRef.current.capture();
      const context = canvasRef.current.getContext("2d");

      const image = new Image();
      image.onload = () => {
        canvasRef.current.width = image.width;
        canvasRef.current.height = image.height;
        context.drawImage(image, 0, 0, image.width, image.height);

        onSubmitted(imageSrc, image.width, image.height, requestId);
      };
      image.src = imageSrc;
    },
    [cameraRef, canvasRef, onSubmitted]
  );

  const [socketUrl, setSocketUrl] = useState(
    "wss://o092hdht73.execute-api.eu-central-1.amazonaws.com/Prod"
  );
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);
  useEffect(() => {
    if (!!lastMessage && !!lastMessage.data) {
      const data = JSON.parse(lastMessage.data);
      capture(data.requestId);
    }
  }, [lastMessage, capture]);

  useEffect(() => {
    if (readyState === 3) {
      setSocketUrl(socketUrl);
    }
  }, [readyState]);

  return (
    <Container
      header={
        <Header
          variant="h2"
          description='In this area you can see through the eyes of Alexa. When you say the magic words "Alexa, what do you see using the AWS webcam" she will detect the objects that are present in this area.'
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button onClick={() => capture()} disabled={loading}>
                {loading ? "Processing..." : "Capture"}
              </Button>
            </SpaceBetween>
          }
        >
          Alexa, what do you see using the AWS webcam.
        </Header>
      }
    >
      <ColumnLayout columns={2}>
        <FreezableWebcam ref={cameraRef}></FreezableWebcam>
        <canvas style={{ width: "100%", height: "100%" }} ref={canvasRef} />
      </ColumnLayout>
      <ColumnLayout columns={1}>
        <SpaceBetween direction="horizontal" size="xs">
          {badges.map((badge) => (
            <Badge key={badge}>{badge}</Badge>
          ))}
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
}

export default Rekognizer;
