import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import {
  AppLayout,
  Container,
  ContentLayout,
  Grid,
  Header,
} from "@cloudscape-design/components";
import { Amplify, Auth } from "aws-amplify";
import CompanyCloud from "./components/company-cloud/CompanyCloud";
import ObjectCloud from "./components/object-cloud/ObjectCloud";
import Rekognizer from "./components/rekognizer/Rekognizer";

Amplify.configure({
  Auth: {
    region: "eu-central-1",
    userPoolId: "eu-central-1_lGbru3zX8",
    userPoolWebClientId: "7d2qnc5remfee7tgla09kivpuf",
  },
});
Auth.configure();

function App({ signOut, user }) {
  return (
    <AppLayout
      headerSelector="#header"
      toolsHide
      navigationHide
      content={
        <ContentLayout
          header={<Header variant="h1">JFall Booth Dashboard</Header>}
        >
          <Grid
            gridDefinition={[
              { colspan: 5 },
              { colspan: 5 },
              { colspan: 2 },
              { colspan: 12 },
            ]}
          >
            <Container
              header={
                <Header
                  variant="h2"
                  description="Place some props on the table. Ask Alexa what she sees. These are the most common things Alexa has seen today."
                >
                  Object detection
                </Header>
              }
            >
              <ObjectCloud />
            </Container>
            <Container
              header={
                <Header variant="h2" description="Represent your company here!">
                  Company Cloud
                </Header>
              }
            >
              <CompanyCloud />
            </Container>
            <img
              src="/qr.png"
              width={"100%"}
              alt="https://form.jfall.brentvw.people.aws.dev/"
            />
            <Rekognizer />
          </Grid>
        </ContentLayout>
      }
      // breadcrumbs={<Breadcrumbs />}
      // navigation={<Navigation />}
      // tools={toolsContent}
      // toolsOpen={toolsOpen}
      // onToolsChange={({ detail }) => setToolsOpen(detail.open)}
      // ariaLabels={appLayoutLabels}
      // notifications={<Notifications />}
    ></AppLayout>
  );
}

export default withAuthenticator(App, { hideSignUp: true });
